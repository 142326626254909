export type ColorschemeName = 'blue' | 'yellow' | 'red';

/**
 * The values that are defined here are the default values that can be overridden by env.js.
 */
export class EnvService {
  /**
   * Главная иконка
   */
  public mainIcon: string = '/assets/images/sso-logo-red.png';
  /**
   * Главная иконка маленькая
   */
  public mainMinIcon: string = '/assets/images/sso-logo-min-red.png';
  /**
   * Текст рядом с главной иконкой
   */
  public mainText: string = 'Центральная\nCлужба\nАутентификации';
  /**
   * Favicon
   */
  public favicon: string = 'favicon.ico';
  /**
   * Цветовая схема
   */
  public colorscheme: ColorschemeName = 'blue';

  constructor() {
  }
}
