import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { share } from "rxjs/operators";
import { ISystemParam } from "@core/interfaces/system-params";
import { IAdminSystemParamUpdateParams } from "@core/interfaces/system-params";
import { MapCommonGrid } from "@app/core/functions/grid-mappers";
import { IGridRequestPayload, IPagedResponse } from "ngx-strong-frontend-lib/grid/interfaces";

@Injectable({
  providedIn: 'root'
})
export class SystemParamsApiService {

  constructor(
    private HttpClient: HttpClient
  ) { }

  public getPublicSystemParams(): Observable<ISystemParam[]> {
    return this.HttpClient.get<ISystemParam[]>(
      `${environment.API_URL}/public/parameters`
    ).pipe(share());
  }

  @MapCommonGrid()
  public getSystemParamsGrid(requestPayload: IGridRequestPayload): Observable<IPagedResponse> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/system/parameter/grid`,
      requestPayload
    ).pipe(share());
  }

  public updateSystemParameter(params: IAdminSystemParamUpdateParams) {
    return this.HttpClient.put(`${environment.API_URL}/system/parameter/update`, params).pipe(share());
  }
}
