export function ngThrottle(time: number = 100) {
    return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
        const origFunc = descriptor.value;
        let timerId = null;

        descriptor.value = function (...args) {
            if (!timerId) {
                timerId = setTimeout(() => {
                    clearTimeout(timerId);
                    timerId = null;
                }, time);
                origFunc.apply(this, args);
            }
        }
        return descriptor;
    }
}
