import { Injectable } from '@angular/core';
import { Observable } from "rxjs/internal/Observable";
import { LocalStorageService } from "ngx-strong-frontend-lib/services/local-storage";
import { BehaviorSubject } from "rxjs";
import { IUserMenu } from "@core/interfaces/user";
import { AuthService } from "@core/services/auth/auth.service";
import {MENU_ICONS, MENU_OPENED_KEY, MENU_OPENED_URLS, MENU_URLS, USER_MENU_KEY} from "@app/app.enums";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private openedMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userMenu$: BehaviorSubject<IUserMenu[]> = new BehaviorSubject<IUserMenu[]>([]);

  private activeUrl: string = null;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {
    this.getUserMenuTree();
  }

  public getUserMenuTree() {
    const menuFromStorage: IUserMenu[] = this.localStorageService.getObjectByName(USER_MENU_KEY);
    if (menuFromStorage && this.authService.isAuthorized()) {
      this.setUserMenu(menuFromStorage);
    }
  }

  public getUserMenu(): Observable<IUserMenu[]> {
    return this.userMenu$.asObservable();
  }

  public setUserMenu(userMenu: IUserMenu[]) {
    this.userMenu$.next(userMenu);
    this.localStorageService.setObjectByName(USER_MENU_KEY, userMenu);
  }

  public setUserMenuWithPrepare(menu: IUserMenu[]) {
    this.setActiveMenu(this.prepareMenu(menu), this.activeUrl);
  }

  public setOpenedMenu(open: boolean) {
    this.openedMenu$.next(open);
    this.localStorageService.setObjectByName(MENU_OPENED_KEY, open);
  }

  public getOpenedMenu(): Observable<boolean> {
    return this.openedMenu$.asObservable();
  }

  private prepareMenu(menu: IUserMenu[]): IUserMenu[] {
    let result: IUserMenu[] = [];
    result = menu.map((level: IUserMenu) => {
      return {
        name: level.name,
        code: level.code,
        sortOrder: level.sortOrder,
        isActive: false,
        url: this.getMenuUrl(level.code),
        iconClass: this.getMenuIconClass(level.code)
      }
    });
    return result.length > 0
      ? result.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
      : [];
  }

  private getMenuIconClass(code: string) {
    const iconClass = MENU_ICONS[code];
    return iconClass ? iconClass : null;
  }

  private getMenuUrl(code: string) {
    const url = MENU_URLS[code];
    return url ? url : null;
  }

  public setActiveMenu(menu: IUserMenu[], url: string) {
    if (menu && menu.length > 0) {
      menu = menu.map((item: IUserMenu) => {
        item.isActive = item.url === url;
        return item;
      });
      this.setUserMenu(menu);
    }
  }

  public setActiveUrl(url: string) {
    this.activeUrl = url;
    this.setActiveMenu(this.userMenu$.getValue(), this.activeUrl);
    this.setOpenedMenu(MENU_OPENED_URLS.includes(this.activeUrl));
  }
}
