<div class="menuClass"
     [style.width.px]="menuWidth"
     [@inOutAnimation]
     *ngIf="isOpenMenu | async">
  <div class="menu-head">
    <span class="menu-close font-icons icon-close"
          (click)="closeMenu()"></span>
  </div>
  <div class="menu-body">
    <div #menuList
         class="menu-list scroll-menu-block">
      <div *ngFor="let item of menu"
           class="menu-item">
        <div class="menu-item-title"
             [class.active]="item.isActive"
             [routerLink]="item.url">
          <span class="font-icons-auth menu-item-icon {{item.iconClass}}"></span>
          <span class="text">{{item.name}}</span>
        </div>
      </div>
    </div>
  </div>
</div>