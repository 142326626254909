import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {share} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PasswordApiService {

  constructor(
    private HttpClient: HttpClient
  ) {}

  public sendRequestChangePassword(email: string): Observable<any> {
    return this.HttpClient.post<any>(
      `${environment.API_URL}/public/restore/instruction`,
      {email: email}
    ).pipe(share());
  }

  public checkSecretCode(secretCode: string): Observable<any> {
    return this.HttpClient.post<any>(
      `${environment.API_URL}/public/code/check`,
      {code: secretCode}
    ).pipe(share());
  }

  public resetPassword(newPassword: string, secretCode: string): Observable<any> {
    return this.HttpClient.post<any>(
      `${environment.API_URL}/public/restore/password`,
      {newPassword: newPassword, code: secretCode}
    ).pipe(share());
  }

  public changePassword(newPassword: string, oldPassword: string): Observable<any> {
    return this.HttpClient.put<any>(
      `${environment.API_URL}/user/password/change`,
      {newPassword: newPassword, oldPassword: oldPassword}
    ).pipe(share());
  }

  public setTemporaryPassword(userId: number, newPassword: string): Observable<any> {
    return this.HttpClient.put<any>(
      `${environment.API_URL}/user/password/set`,
      {newPassword: newPassword, id: userId}
    ).pipe(share());
  }
}
