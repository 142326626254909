import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from '@core/services/auth/auth.service';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateInitializerFactory } from '@core/services/translate-initializer.service';
import { OAuth2Service } from "@core/services/auth/oauth2.service";
import { RestoreAccessGuardService } from "@core/guards/restore-access-guard.service";
import { LoadingChunkFailErrorHandler } from 'ngx-strong-frontend-lib/services/loading-chunk-fail-error-handler';
import { ContentTypeInterceptor } from "@core/services/interceptors/content-type.interceptor";
import { AdminAccessGuardService } from "@core/guards/admin-access-guard.service";
import { AuthorizationGuard } from "@core/guards/authorization-guard.service";
import { ExitGuard } from "@core/guards/exit-guard.service";
import { EnvServiceProvider } from "@core/services/env.service.provider";
import { TokenInterceptorConfig, TokenInterceptorModule } from 'ngx-strong-frontend-lib/services/token-interceptor';
import { tokenInterceptorConfigFactory } from './functions/token-interceptor-config-factory';
import { Router } from '@angular/router';
import { TokenSyncConfig, TokenSyncModule } from 'ngx-strong-frontend-lib/services/token-sync';
import { tokenSyncConfigFactory } from './functions/token-sync-config-factory';
import { BroadcastModule } from 'ngx-strong-frontend-lib/services/broadcast';
import { colorschemeInitializer } from './functions/colorscheme-initializer';
import { EnvService } from './services/env.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TokenInterceptorModule.forRoot(
      {
        provide: TokenInterceptorConfig,
        useFactory: tokenInterceptorConfigFactory,
        deps: [
          AuthService,
          Router
        ]
      }
    ),
    TokenSyncModule.forRoot(
      {
        provide: TokenSyncConfig,
        useFactory: tokenSyncConfigFactory,
        deps: [
          AuthService
        ]
      }
    ),
    BroadcastModule
  ],
  declarations: [],
  providers: [
    AuthGuard,
    RestoreAccessGuardService,
    AdminAccessGuardService,
    AuthorizationGuard,
    ExitGuard,
    { provide: AuthService, useClass: OAuth2Service },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: colorschemeInitializer,
      deps: [EnvService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: LoadingChunkFailErrorHandler
    },
    EnvServiceProvider
  ]
})
export class CoreModule { }
