<div class="HeaderSupportClass">
  <div class="header-support-item"
       *ngIf="userHasEmail"
       #settingsBalloonAnchor>
    <lib-button #buttonNotifications
                class="header-settings"
                [iconsClass]="'font-icons-auth'"
                [buttonStyles]="[EButtonStyle.ICON]"
                icon="icon-settings_notifications"
                iconSize="25"
                focusTrapRestore
                #FTRSettings="focusTrapRestore"
                (OnClick)="setSettingsBalloonOpened(true)"
                (clickOutside)="setSettingsBalloonOpened(false)"
                [clickOutsideEnabled]="showSettingsBalloon"
                exclude="div.header-settings-balloon"
                [excludeBeforeClick]="true">
    </lib-button>

    <lib-popup-container-template [popupContainerTarget]="settingsBalloonAnchor"
                                  [popupContainerShow]="showSettingsBalloon"
                                  popupContainerOpenDirection="bottom right"
                                  [popupContainerOffsetRelative]="[0,10]"
                                  [popupContainerAutofocusEvent]="buttonNotifications.OnClick | async">
      <div class="header-settings-balloon"
           focusTrap
           (keydown.Escape)="setSettingsBalloonOpened(false); FTRSettings.restoreTabIndex()">
        <lib-loader [show]="isLoading"></lib-loader>
        <div class="header-settings-balloon-title">
          {{'GENERAL.NOTIFICATIONS.TITLE' | translate}}
        </div>
        <form class="header-settings-balloon-content"
              [formGroup]="settingsForm">
          <div class="header-settings-balloon-item">
            <lib-switch formControlName="isNotificationLoginSuccess"
                        class="header-settings-balloon-item-input"
                        [title]="'GENERAL.NOTIFICATIONS.LOGIN_SUCCESS' | translate"
                        name="isNotifLoginSuccess"
                        (OnChange)="changeField($event, 'isNotificationLoginSuccess')">
            </lib-switch>
          </div>
          <div class="header-settings-balloon-item">
            <lib-switch formControlName="isNotificationLoginFail"
                        class="header-settings-balloon-item-input"
                        [title]="'GENERAL.NOTIFICATIONS.LOGIN_FAIL' | translate"
                        name="isNotifLoginFail"
                        (OnChange)="changeField($event, 'isNotificationLoginFail')">
            </lib-switch>
          </div>
          <div class="header-settings-balloon-item">
            <lib-switch formControlName="isNotificationLogout"
                        class="header-settings-balloon-item-input"
                        [title]="'GENERAL.NOTIFICATIONS.LOGOUT' | translate"
                        name="isNotifLogout"
                        (OnChange)="changeField($event, 'isNotificationLogout')">
            </lib-switch>
          </div>
        </form>
      </div>
    </lib-popup-container-template>
  </div>
  <div class="header-support-item"
       #supportBalloonAnchor>
    <lib-button #buttonSupport
                class="header-support"
                [buttonStyles]="[EButtonStyle.ICON]"
                icon="icon-support_header"
                iconSize="21"
                focusTrapRestore
                #FTRSupport="focusTrapRestore"
                (OnClick)="setSupportBalloonOpened(true)"
                (clickOutside)="setSupportBalloonOpened(false)"
                [clickOutsideEnabled]="showSupportBalloon"
                exclude="div.header-support-balloon"
                [excludeBeforeClick]="true">
    </lib-button>
  </div>
  <div class="header-support-item"
       #userBalloonAnchor>
    <lib-button #buttonUser
                class="header-user"
                [buttonStyles]="[EButtonStyle.ICON]"
                icon="icon-user_logo"
                iconSize="25"
                focusTrapRestore
                #FTRUser="focusTrapRestore"
                (OnClick)="setUserBalloonOpened(true)"
                (clickOutside)="setUserBalloonOpened(false)"
                [clickOutsideEnabled]="showUserBalloon"
                exclude="div.header-user-balloon"
                [excludeBeforeClick]="true">
    </lib-button>
  </div>
</div>

<lib-popup-container-template [popupContainerTarget]="supportBalloonAnchor"
                              [popupContainerShow]="showSupportBalloon"
                              popupContainerOpenDirection="bottom right"
                              [popupContainerOffsetRelative]="[0,10]"
                              [popupContainerAutofocusEvent]="buttonSupport.OnClick |async">
  <div class="header-support-balloon"
       focusTrap
       (keydown.Escape)="setSupportBalloonOpened(false); FTRSupport.restoreTabIndex()">
    <div class="header-support-balloon-title">{{'GENERAL.SUPPORT' | translate}}</div>
    <div class="header-support-balloon-item">
      <span class="font-icons icon-phone"></span>
      <span class="text">{{supportPhone}}</span>
    </div>
    <div class="header-support-balloon-item">
      <span class="font-icons icon-mail"></span>
      <a href="mailTo:{{supportEmail}}"
         target="_blank"
         class="outer-href">{{supportEmail}}</a>
    </div>
  </div>
</lib-popup-container-template>

<lib-popup-container-template [popupContainerTarget]="userBalloonAnchor"
                              [popupContainerShow]="showUserBalloon"
                              popupContainerOpenDirection="bottom right"
                              [popupContainerOffsetRelative]="[0,10]"
                              [popupContainerAutofocusEvent]="buttonUser.OnClick | async">
  <div class="header-user-balloon"
       focusTrap
       (keydown.Escape)="setUserBalloonOpened(false); FTRUser.restoreTabIndex()">
    <div class="header-user-balloon-title"
         [innerHTML]="user ? user.name : ''"></div>
    <div class="header-user-balloon-item">
      <span class="font-icons icon-phone"></span>
      <span class="text">{{user ? user.phone_number : ''}}</span>
    </div>
    <div class="header-user-balloon-item">
      <span class="font-icons icon-mail"></span>
      <span class="text">{{user ? user.email : ''}}</span>
    </div>
    <button *ngIf="user && !user.is_external"
            class="header-user-balloon-item-button"
            (click)="changePassword()">
      {{'GENERAL.CHANGE_PASSWORD' | translate}}
    </button>
    <button class="header-user-balloon-item-button"
            (click)="exit()">
      {{'GENERAL.EXIT' | translate}}
    </button>
  </div>
</lib-popup-container-template>
