import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "@core/guards/auth-guard.service";
import { NgModule } from '@angular/core';
import { AdminAccessGuardService } from "@core/guards/admin-access-guard.service";
import { AuthorizationGuard } from "@core/guards/authorization-guard.service";
import { ExitComponent } from "@shared/components/exit/exit.component";
import { ExitGuard } from "@core/guards/exit-guard.service";
import { QuicklinkStrategy } from "ngx-quicklink";
import { PageNotFoundComponent } from 'ngx-strong-frontend-lib/components/page-not-found';
import { NoAccessComponent } from 'ngx-strong-frontend-lib/components/no-access';
import { PageWrongComponent } from 'ngx-strong-frontend-lib/components/page-wrong';

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    data: {
      preload: false
    }
  },
  {
    path: 'auth',
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'restore',
    loadChildren: () => import('./modules/restore-access/restore-access.module').then(m => m.RestoreAccessModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./modules/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  {
    path: 'access-admin',
    canActivate: [AdminAccessGuardService],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  { path: 'exit', component: ExitComponent, canActivate: [ExitGuard] },
  { path: 'not-access', component: NoAccessComponent },
  { path: 'page-wrong', component: PageWrongComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: QuicklinkStrategy })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

