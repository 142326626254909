import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "@core/services/auth/auth.service";
import { Location } from "@angular/common";
import {UserService} from "@core/services/user.service";
import { LocalStorageService } from 'ngx-strong-frontend-lib/services/local-storage';

@Injectable()
export class AdminAccessGuardService  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private userService: UserService,
    private localStorage: LocalStorageService
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userInfo = this.userService.userInfo;

    if (!this.authService.isAuthorized()) {
      this.navigateToLogin(state.url);
      return false;
    }

    if (!userInfo || !(userInfo && userInfo.is_access_admin)) {
      this.navigateToNoAccess(state.url);
      return false;
    }

    return true;
  }

  private navigateToLogin(url: string): void {
    this.localStorage.setObjectByName('redirectTo', url && url !== '' && url !== '/' ? url : null);
    this.router.navigate(['/auth']);
  }

  private navigateToNoAccess(url: string): void {
    this.router.navigate(['/not-access']).then(() => {
      this.location.replaceState(url);
    });
  }
}
