<div class="inputClass">
  <input
    id="{{inputId}}"
    #input
    class="input-component"
    name="{{inputId}}"
    [maxlength]="maxLength"
    [minlength]="minLength"
    [max]="maxValue"
    [min]="minValue"
    [style.height.px]="inputHeight"
    [class.not-empty]="!!model && !disablePlaceholderAnimation"
    [class.border-without-label]="disablePlaceholderAnimation"
    [(ngModel)]="model" [type]="type"
    [step]="step"
    (focus)="emitFocusEvent($event)"
    (blur)="emitBlurEvent($event)"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [readonly]="readonly"
    [mask]="mask"
    [pattern]="pattern">
  <span *ngIf="iconClass"
        class="font-icons-auth {{iconClass}}"
        [style.font-size.px]="iconSize"
        [style.color]="iconColor"></span>
  <span class="input-label-container" *ngIf="!disablePlaceholderAnimation">
    <label for="{{inputId}}" class="input-label">
      {{placeholder}}
    </label>
  </span>
  <div class="input__buttons" *ngIf="type === 'number' && !disabled && !hideArrows">
    <div class="input__button" (mousedown)="arrowPressed($event, 'increase')">
      <span class="input__button-icon"></span>
    </div>
    <div class="input__button decrease" (mousedown)="arrowPressed($event, 'decrease')">
      <span class="input__button-icon decrease"></span>
    </div>
  </div>
</div>
