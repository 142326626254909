import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseAuthService } from 'ngx-strong-frontend-lib/services/auth';
import { LocalStorageService } from 'ngx-strong-frontend-lib/services/local-storage';
import { ITokenResponse } from 'ngx-strong-frontend-lib/interfaces';

@Injectable()
export abstract class AuthService
  extends BaseAuthService {

  constructor(
    protected localStorageService: LocalStorageService
  ) {
    super(localStorageService);
  }

  /**
   * Вход в систему
   * @param username логин
   * @param password пароль
   */
  abstract login(username: string, password: string, externalSystemId?: number): Observable<ITokenResponse>;
  abstract refreshToken(): Observable<ITokenResponse>;
}
