import {Injectable} from '@angular/core';
import {AuthService} from "@core/services/auth/auth.service";
import {LocalStorageService} from "ngx-strong-frontend-lib/services/local-storage";
import {UserApiService} from "@core/services/api/user-api.service";
import {INotifications, IUserInfo} from "@core/interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userInfoKey: string = 'authUserInfo';
  private _userInfo: any;


  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private userApiService: UserApiService
  ) {
    this._userInfo = this.localStorageService.getObjectByName(this.userInfoKey);
    /**
     * bugfix ASUER-7712
     * Запрос отправляется каждый раз при открытии приложения для "авторизованного" пользователя
     */
    if (this.authService.isAuthorized() && !document.location.href.includes('/exit')) {
      this.userApiService.getUserInfo().subscribe(
        (userInfo: IUserInfo) => {
          this.userInfo = userInfo;
      });
    }
  }

  get userInfo(): any {
    return this._userInfo;
  }

  set userInfo(userInfo: any) {
    this._userInfo = userInfo;
    this.localStorageService.setObjectByName(this.userInfoKey, this._userInfo);
  }

  public setUserNotifications(notifications: INotifications) {
    this.userInfo = {...this._userInfo, ...notifications};
  }

  public logout() {
    this.authService.logout().subscribe(
      responce => {
        this.clearUserSession();
      },
      error => {
        this.clearUserSession();
      }
    );
  }

  public clearUserSession() {
    this.userInfo = null;
    this.localStorageService.removeAll();
    window.location.href = window.location.origin;
  }
}
