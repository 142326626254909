<div class="searchFieldAuthClass" [class.transparent]="withTransparentBackground">
  <div class="search-field-block">
    <input #inputField type="text"
           class="search-field-input"
           id="{{searchId}}"
           name="{{searchId}}"
           [placeholder]="placeholder"
           [ngModel]="value"
           maxlength="255"
           [class.has-error]="hasError"
           (keydown.enter)="search($event)"/>
    <div class="search-field-poisk" (click)="search($event)">
      <span class="font-icons icon-search"></span>
    </div>
    <div *ngIf="value && value !== ''" class="search-field-clear" (click)="clear()">
      <span class="font-icons icon-clear"></span>
    </div>
  </div>
  <div *ngIf="hasError && errorText" class="search-field-error">{{errorText}}</div>
</div>
