import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IEventLogChange, IEventLogError, IEventLogItem } from "@core/interfaces/event-log";
import { EventLogApiService } from "@core/services/api/event-log-api.service";
import { IAccordionItem } from "ngx-strong-frontend-lib/interfaces";
import { DateTimeService } from "ngx-strong-frontend-lib/services/date-time";
import { DateFormat } from 'ngx-strong-frontend-lib/enums';

@Component({
  selector: 'app-event-log-card',
  templateUrl: './event-log-card.component.html',
  styleUrls: ['./event-log-card.component.scss']
})
export class EventLogCardComponent implements OnInit {
  @Input() info: IEventLogItem;

  public eventTypeTitle: string = null;
  public startDate: string = null;
  public endDate: string = null;
  public changes: IEventLogChange[] = [];
  public errors: IEventLogError[] = [];

  public isLoading: boolean = false; // флаг загрузки

  public blocks: IAccordionItem[] = [];

  private readonly dateFormat: string = DateFormat.DATE_TIME_WITH_SECONDS;

  constructor(
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
    private eventLogApiService: EventLogApiService,
    private dateTimeService: DateTimeService
  ) {
    this.translateService.get(['ADMIN.EVENT_LOG.CARD.BLOCKS']).subscribe(result => {
      this.blocks = [
        { id: 'params', title: result['ADMIN.EVENT_LOG.CARD.BLOCKS']['PARAMS'], open: true },
        { id: 'changes', title: result['ADMIN.EVENT_LOG.CARD.BLOCKS']['CHANGED_ATTRIBUTES'], open: false },
        { id: 'errors', title: result['ADMIN.EVENT_LOG.CARD.BLOCKS']['ERRORS'], open: false }
      ];
    });
  }

  ngOnInit(): void {
    if (this.info && this.info.id) {
      this.eventTypeTitle = this.info.event_type_name;
      this.startDate = this.info.date_start
        ? this.dateTimeService.getDateFromBackend(this.info.date_start, this.dateFormat)
        : null;
      this.endDate = this.info.date_end
        ? this.dateTimeService.getDateFromBackend(this.info.date_end, this.dateFormat)
        : null;
      this.getChanges();
      if (this.info.error_cnt > 0) {
        this.getErrors();
      } else {
        this.errors = [];
      }
    } else {
      this.changes = [];
      this.errors = [];
    }
  }

  private getChanges() {
    this.isLoading = true;
    this.eventLogApiService.getEventInfo(this.info.id)
      .subscribe((result: IEventLogChange[]) => {
        this.changes = result && result.length > 0 ? this.prepareChanges(result) : [];
        this.isLoading = false;
      }, error => {
        this.changes = [];
        this.isLoading = false;
      });
  }

  private getErrors() {
    this.isLoading = true;
    this.eventLogApiService.getEventErrors(this.info.id)
      .subscribe((result: IEventLogError[]) => {
        this.errors = result && result.length > 0 ? this.prepareErrors(result) : [];
        this.isLoading = false;
      }, error => {
        this.errors = [];
        this.isLoading = false;
      });
  }

  private prepareChanges(list: IEventLogChange[]) {
    const items: IEventLogChange[] = list.map((item: IEventLogChange) => {
      item.change_from = item.change_from && item.change_from !== '' && item.change_from !== "null"
        ? this.dateTimeService.getDateOrText(item.change_from, this.dateFormat) : null;
      item.change_to = item.change_to && item.change_to !== '' && item.change_to !== "null"
        ? this.dateTimeService.getDateOrText(item.change_to, this.dateFormat) : null;
      return item;
    });
    return items.filter((item: IEventLogChange) => item.change_from !== item.change_to);
  }

  private prepareErrors(list: IEventLogError[]) {
    return list.map((item: IEventLogError) => {
      item.error_date = item.error_date
        ? this.dateTimeService.getDateFromBackend(item.error_date, this.dateFormat)
        : null;
      return item;
    });
  }

  public close() {
    this.info = null;
    this.eventTypeTitle = null;
    this.startDate = null;
    this.endDate = null;
    this.changes = [];
    this.errors = [];
    this.activeModal.close();
  }
}
