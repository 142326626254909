import {
    IGridRequestPayload,
    IPagedResponse
} from "ngx-strong-frontend-lib/grid/interfaces"
import {
    IGridRequestPayload_AUTH,
    IPagedResponse_AUTH
} from "@app/shared/components/base/ag-base-grid/ag-base-grid.interface"
import { IKeyValuePairs, ISelectItem } from "ngx-strong-frontend-lib/interfaces"
import { map, Observable, share } from "rxjs";
import type { AgBaseGridFiltersService } from 'ngx-strong-frontend-lib/grid';
import { DateFormat } from "ngx-strong-frontend-lib/enums";
import dayjs from 'dayjs';

/**
 * Функция для маппинга значений фильтров с выбором из списка.  
 * По умолчанию {@link AgBaseGridFiltersService} мапит такие значения в строку вида 'a;b;c'.  
 * Но бэкенд ПАА принимает массивы айдишников.  
 * @param value значение
 * @example
 * protected readonly defaultFilters: IUsersFilters = {
 *  ...
 *  subsystem_ids: {
        field: 'subsystem_ids',
        value: null,
        prepareFilterValue: prepareMultiSelectFilterValue,
    },
    ...
   }
 * @returns number[]
 */
export const prepareMultiSelectFilterValue = <T extends ISelectItem[]>(value: T): Array<number | string> | T => {
    if (!Array.isArray(value)) {
        return value;
    }
    if (!value[0]?.key) {
        return value;
    }
    return value.map(item => item.key);
}

/**
 * Подготовить дату для фильтров с миллисекундами и тз
 * @param value дата
 * @param format формат даты
 * @param range начало или окончание периода (начало или окончание дня)
 * @returns 2024-08-02T23:59:59.999+03:00
 */
export const prepareJsonDateMSTZFilterValue = (
    value: string,
    format: DateFormat,
    range?: 'start' | 'end'
) => {
    if (!value) {
        return null;
    }
    let djs = dayjs(value, format);
    if (range === 'start') {
        djs = djs.startOf('day');
    }
    if (range === 'end') {
        djs = djs.endOf('day');
    }
    return djs.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}

/**
 * Функция преобразования универсального пейлоада (из библиотеки)
 * в локальный пейлоад (для апи ПАА).
 * @param payload универсальный пейлоад грида
 * @returns пейлоад гридов ПАА
 */
export function mapGridPayload<
    In extends IGridRequestPayload,
    Out extends IGridRequestPayload_AUTH
>(payload: In): Out {
    const {
        page,
        sortings,
        search,
        compositeFilters,
        disableCount,
        ...otherFields
    } = { ...payload };
    const filters: IKeyValuePairs = {};
    compositeFilters?.forEach(cf => {
        cf.filters?.forEach(f => {
            filters[f.field] = f.value;
        });
    });
    return {
        ...otherFields as any,
        pagination: page,
        sortings,
        search,
        filters,
        disableCount,
    } as Out;
}

/**
 * Функция преобразования универсального пейлоада (из библиотеки)
 * в локальный пейлоад (для апи ПАА).
 * @param payload универсальный пейлоад грида
 * @returns пейлоад спарвочников-гридов ПАА
 */
export function mapGridDictionaryPayload<
    In extends IGridRequestPayload,
    Out extends IGridRequestPayload_AUTH
>(payload: In): Out {
    let {
        page,
        sortings,
        search,
        compositeFilters,
        disableCount,
        ...otherFields
    } = { ...payload };
    const filters: IKeyValuePairs = {};
    compositeFilters?.forEach(cf => {
        cf.filters?.forEach(f => {
            filters[f.field] = f.value;
        });
    });
    if (otherFields && otherFields['sorting']) {
        if (sortings?.length > 0){
            sortings?.forEach(sort => {
                sort.direction = otherFields['sorting'];
            });  
        }
    }
    return {
        ...otherFields as any,
        pagination: page,
        sortings,
        search,
        filters,
        disableCount,
    } as Out;
}

/**
 * Функция преобразования локального ответа (из апи ПАА)
 * в универсальный ответ (из библиотеки).
 * @param response ответ гридового апи ПАА
 * @returns ответ универсального апи
 */
export function mapGridResponse<
    In extends IPagedResponse_AUTH,
    Out extends IPagedResponse
>(response: In): Out {
    const {
        elements,
        total,
        totalPages,
        pageNumber,
        pageSize,
        ...otherFields
    } = { ...response };
    return {
        ...otherFields as any,
        elements,
        totalElements: total,
        totalPages,
        pageNumber,
        pageSize,
    }
}

export type TGridRequestMethod<In extends IGridRequestPayload = IGridRequestPayload, Out extends IPagedResponse = IPagedResponse> = (payload: In, ...args: any) => Observable<Out>;

/**
 * Декоратор для методов запроса гридов.  
 * Метод должен принимать и возвращать стандартные данные {@link IGridRequestPayload} и {@link IPagedResponse}.  
 * Декоратор преобразовывает их в {@link IGridRequestPayload_AUTH} и {@link IPagedResponse_AUTH} соответственно.  
 * Возможно использование доп. параметров метода после пейлоада.
 * @returns 
 */
export function MapCommonGrid() {
    return function (
        target: Object,
        key: string | symbol,
        descriptor: TypedPropertyDescriptor<TGridRequestMethod>
    ): TypedPropertyDescriptor<TGridRequestMethod> {
        const originalHandler = descriptor.value;
        descriptor.value = function (this: any, payload: IGridRequestPayload, ...args: any) {
            const mappedPayload = mapGridPayload(payload);
            return originalHandler.apply(
                this,
                [mappedPayload, ...args]
            ).pipe(
                map<IPagedResponse_AUTH, IPagedResponse>(res => mapGridResponse(res)),
                share(),
            );
        };
        return descriptor;
    }
}

/**
 * Декоратор для методов запроса гридов.  
 * Метод должен принимать и возвращать стандартные данные {@link IGridRequestPayload} и {@link IPagedResponse}.  
 * Декоратор преобразовывает их в {@link IGridRequestPayload_AUTH} и {@link IPagedResponse_AUTH} соответственно.  
 * Возможно использование доп. параметров метода после пейлоада.
 * @returns 
 */
export function MapCommonDictionary() {
    return function (
        target: Object,
        key: string | symbol,
        descriptor: TypedPropertyDescriptor<TGridRequestMethod>
    ): TypedPropertyDescriptor<TGridRequestMethod> {
        const originalHandler = descriptor.value;
        descriptor.value = function (this: any, payload: IGridRequestPayload, ...args: any) {
            const mappedPayload = mapGridDictionaryPayload(payload);
            return originalHandler.apply(
                this,
                [mappedPayload, ...args]
            ).pipe(
                share(),
            );
        };
        return descriptor;
    }
}