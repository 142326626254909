import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import {Location} from "@angular/common";
import {AuthService} from "@core/services/auth/auth.service";
import {UserService} from "@core/services/user.service";
import { LocalStorageService } from 'ngx-strong-frontend-lib/services/local-storage';

@Injectable()
export class ExitGuard  {

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.authService.clearCredentials();
    this.userService.userInfo = null;
    this.localStorageService.removeAll();
    this.router.navigate(['/auth']);
    return false;
  }
}
