import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { BreadcrumbsService } from 'ngx-strong-frontend-lib/services/breadcrumbs';
import { MenuService } from "@shared/services/menu.service";
import { EButtonStyle } from 'ngx-strong-frontend-lib/components/button';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public mainUrl: string = '/home';
  public breadcrumbs$ = this.breadcrumbsService.observeBreadcrumbs({
    renderNeighbours: 'last',
    accessObjectsKey: 'userAccessObjects'
  });
  readonly EButtonStyle = EButtonStyle;

  constructor(
    private router: Router,
    private menuService: MenuService,
    private breadcrumbsService: BreadcrumbsService
  ) { }

  ngOnInit() {
  }

  public toggleMenu(open: boolean) {
    this.menuService.setOpenedMenu(open);
  }

  public downloadGuide() {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    const url = '/assets/documents/И5_Руководство_администратора.docx';
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', 'И5_Руководство_администратора.docx');
    downloadLink.click();
  }

}
