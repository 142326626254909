import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {Observable} from "rxjs";
import {share} from "rxjs/operators";
import {INotifications, IUserInfo} from "@core/interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(
    private HttpClient: HttpClient
  ) {}

  public getUserInfo(): Observable<IUserInfo> {
    return this.HttpClient.get<IUserInfo>(`${environment.API_URL}/user/profile`).pipe(share());
  }

  public updateUserNotifications(notifications: INotifications): Observable<any> {
    return this.HttpClient.put<any>(`${environment.API_URL}/user/notifications/change`, notifications).pipe(share());
  }
}
