<div class="changePasswordFromClass">
  <lib-loader [show]="isLoading"></lib-loader>
  <div class="modal-header">
    <img [src]="mainMinIconUrl"
         class="header-icon" alt="logo" />
    <div class="title">{{'PASSWORD.CHANGE_PASSWORD' | translate}}</div>
  </div>
  <div class="modal-body"
       [class.for-modal]="forModal">
    <form [formGroup]="form"
          class="change-password-form">
      <div class="form-field">
        <div class="form-field-label">
          <span>{{'PASSWORD.CURRENT_PASSWORD' | translate}}</span>
        </div>
        <lib-password-input class="form-field-input"
                            formControlName="currentPassword"
                            inputId="currentPassword"
                            [hasError]="isCurrentPasswordError"
                            [hasSuccess]="!isCurrentPasswordError && !!currentPasswordResult"
                            [resultText]="currentPasswordResult"
                            (OnChange)="changeField($event, 'currentPassword')">
        </lib-password-input>
      </div>
      <div class="form-field">
        <div class="form-field-label">
          <span>{{'PASSWORD.NEW_PASSWORD' | translate}}</span>
          <span *ngIf="newPasswordTooltip && newPasswordTooltip.length > 0"
                class="font-icons icon-info"
                [ngbTooltip]="passwordTooltip"
                tooltipClass="tooltip-with-list"
                placement="top"
                container="body">
          </span>
        </div>
        <lib-password-input class="form-field-input"
                            formControlName="newPassword"
                            inputId="newPassword"
                            [hasError]="isNewPasswordError"
                            [hasSuccess]="!isNewPasswordError && !!newPasswordResult"
                            [resultText]="newPasswordResult"
                            (OnChange)="changeField($event, 'newPassword')">
        </lib-password-input>
      </div>
      <div class="form-field">
        <div class="form-field-label">
          <span>{{'PASSWORD.NEW_PASSWORD_AGAIN' | translate}}</span>
        </div>
        <lib-password-input class="form-field-input"
                            formControlName="newPasswordAgain"
                            inputId="newPasswordAgain"
                            [hasError]="isNewPasswordAgainError"
                            [hasSuccess]="!isNewPasswordAgainError && !!newPasswordAgainResult"
                            [resultText]="newPasswordAgainResult"
                            (OnChange)="changeField($event, 'newPasswordAgain')">
        </lib-password-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <lib-button *ngIf="forModal"
                class="modal-footer-btn"
                (OnClick)="cancel()"
                [title]="'GENERAL.CANCEL' | translate">
    </lib-button>
    <lib-button class="modal-footer-btn filled"
                (OnClick)="changePassword()"
                [title]="forModal ? btnTitleForModal : btnTitle">
    </lib-button>
  </div>
</div>
<ng-template #passwordTooltip>
  <ul>
    <li *ngFor="let tooltip of newPasswordTooltip">{{tooltip}}</li>
  </ul>
</ng-template>
