import { RouterModule } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { ChangePasswordModalComponent } from "@shared/components/change-password-modal/change-password-modal.component";
import { HeaderSupportComponent } from '@shared/components/header-support/header-support.component';
import { HeaderComponent } from "@shared/components/header/header.component";
import { ChangePasswordFormComponent } from '@shared/components/change-password-modal/change-password-form/change-password-form.component';
import { TemporaryPasswordModalComponent } from "@shared/components/temporary-password-modal/temporary-password-modal.component";
import { RestoreAccessFormComponent } from "@shared/components/restore-access-form/restore-access-form.component";
import { ExitComponent } from "@shared/components/exit/exit.component";
import dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsFormats from 'dayjs/plugin/customParseFormat';
import dayjsRu from 'dayjs/locale/ru';
import dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsArraySupport from 'dayjs/plugin/arraySupport';
import { AccordionItemModule } from 'ngx-strong-frontend-lib/components/accordion-item';
import { ActionMenuModule } from 'ngx-strong-frontend-lib/components/action-menu';
import { BasePageWithChildsModule } from 'ngx-strong-frontend-lib/components/base-page-with-childs';
import { BreadcrumbsModule } from 'ngx-strong-frontend-lib/components/breadcrumbs';
import { ButtonModule } from 'ngx-strong-frontend-lib/components/button';
import { CalendarModule } from 'ngx-strong-frontend-lib/components/calendar';
import { CheckboxModule } from 'ngx-strong-frontend-lib/components/checkbox';
import { CheckboxListModule } from 'ngx-strong-frontend-lib/components/checkbox-list';
import { ConfirmModalModule } from 'ngx-strong-frontend-lib/components/confirm-modal';
import { DownloadProgressModule } from 'ngx-strong-frontend-lib/components/download-progress';
import { FilesListModule } from 'ngx-strong-frontend-lib/components/files-list';
import { InputModule } from 'ngx-strong-frontend-lib/components/input';
import { LockEditableModule } from 'ngx-strong-frontend-lib/components/lock-editable';
import { LoaderModule } from 'ngx-strong-frontend-lib/components/loader';
import { MultiDictionaryModule } from 'ngx-strong-frontend-lib/components/multi-dictionary';
import { MultiSelectModule } from 'ngx-strong-frontend-lib/components/multi-select';
import { PasswordInputModule } from 'ngx-strong-frontend-lib/components/password-input';
import { RadioboxListModule } from 'ngx-strong-frontend-lib/components/radiobox-list';
import { RoundProgressBarModule } from 'ngx-strong-frontend-lib/components/round-progress-bar';
import { SearchFieldModule } from 'ngx-strong-frontend-lib/components/search-field';
import { SelectSearchListModule } from 'ngx-strong-frontend-lib/components/select-search-list';
import { SimpleDictionaryModule } from 'ngx-strong-frontend-lib/components/simple-dictionary';
import { SimpleSelectModule } from 'ngx-strong-frontend-lib/components/simple-select';
import { SwitchModule } from 'ngx-strong-frontend-lib/components/switch';
import { TabsModule } from 'ngx-strong-frontend-lib/components/tabs';
import { TextareaModule } from 'ngx-strong-frontend-lib/components/textarea';
import { ToastrModule } from 'ngx-strong-frontend-lib/components/toastr';
import { UniversalTreeModule } from 'ngx-strong-frontend-lib/components/universal-tree';
import { UniversalTreeItemModule } from 'ngx-strong-frontend-lib/components/universal-tree-item';
import { DisableControlModule } from 'ngx-strong-frontend-lib/directives/disable-control';
import { FormatDatePipeModule } from 'ngx-strong-frontend-lib/pipes/format-date';
import { IsVisiblePipeModule } from 'ngx-strong-frontend-lib/pipes/is-visible';
import { RouteParamDirectiveModule } from 'ngx-strong-frontend-lib/directives/route-param';
import { EventLogCardComponent } from "@shared/components/event-log-card/event-log-card.component";
import { SearchFieldAuthComponent } from "@shared/components/search-field-auth/search-field-auth.component";
import { InputAuthComponent } from "@shared/components/input-auth/input-auth.component";
import { MenuAuthComponent } from "@shared/components/menu-auth/menu-auth.component";
import { NoSelectStubModule } from 'ngx-strong-frontend-lib/components/no-select-stub';
import { FocusTrapModule } from 'ngx-strong-frontend-lib/directives/focus-trap';
import { PopupContainerModule } from 'ngx-strong-frontend-lib/components/popup-container';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsArraySupport);
dayjs.locale(dayjsRu);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // TextMaskModule,
        TranslateModule,
        RouterModule,
        NgbModule,
        // ContextMenuModule,
        ClickOutsideModule,
        QuicklinkModule,
        // lib
        AccordionItemModule,
        ActionMenuModule,
        BasePageWithChildsModule,
        BreadcrumbsModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        CheckboxListModule,
        ConfirmModalModule,
        DownloadProgressModule,
        FilesListModule,
        InputModule,
        LoaderModule,
        LockEditableModule,
        MultiDictionaryModule,
        MultiSelectModule,
        PasswordInputModule,
        RadioboxListModule,
        RoundProgressBarModule,
        SearchFieldModule,
        SelectSearchListModule,
        SimpleDictionaryModule,
        SimpleSelectModule,
        SwitchModule,
        TabsModule,
        TextareaModule,
        ToastrModule,
        UniversalTreeModule,
        UniversalTreeItemModule,
        DisableControlModule,
        FormatDatePipeModule,
        IsVisiblePipeModule,
        RouteParamDirectiveModule,
        NoSelectStubModule,
        FocusTrapModule,
        PopupContainerModule,
        NgxMaskDirective
    ],
    declarations: [
        ChangePasswordModalComponent,
        HeaderSupportComponent,
        HeaderComponent,
        ChangePasswordFormComponent,
        TemporaryPasswordModalComponent,
        RestoreAccessFormComponent,
        ExitComponent,
        EventLogCardComponent,
        SearchFieldAuthComponent,
        InputAuthComponent,
        MenuAuthComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        // TextMaskModule,
        NgbModule,
        QuicklinkModule,
        ClickOutsideModule,
        ReactiveFormsModule,
        TranslateModule,
        // ContextMenuModule,
        // lib
        AccordionItemModule,
        ActionMenuModule,
        BasePageWithChildsModule,
        BreadcrumbsModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        CheckboxListModule,
        ConfirmModalModule,
        DownloadProgressModule,
        FilesListModule,
        InputModule,
        LoaderModule,
        LockEditableModule,
        MultiDictionaryModule,
        MultiSelectModule,
        PasswordInputModule,
        RadioboxListModule,
        RoundProgressBarModule,
        SearchFieldModule,
        SelectSearchListModule,
        SimpleDictionaryModule,
        SimpleSelectModule,
        SwitchModule,
        TabsModule,
        TextareaModule,
        ToastrModule,
        UniversalTreeModule,
        UniversalTreeItemModule,
        DisableControlModule,
        FormatDatePipeModule,
        IsVisiblePipeModule,
        RouteParamDirectiveModule,
        NoSelectStubModule,
        FocusTrapModule,
        PopupContainerModule,
        // components
        ChangePasswordModalComponent,
        HeaderSupportComponent,
        HeaderComponent,
        ChangePasswordFormComponent,
        TemporaryPasswordModalComponent,
        RestoreAccessFormComponent,
        ExitComponent,
        EventLogCardComponent,
        InputAuthComponent,
        SearchFieldAuthComponent,
        MenuAuthComponent
    ],
  providers: [
    provideNgxMask()
  ]
})
export class SharedModule { }
