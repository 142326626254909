import { Component } from '@angular/core';
import { EnvService } from '@app/core/services/env.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  public readonly mainMinIconUrl: string = this.envService.mainMinIcon;

  constructor(
    private envService: EnvService
  ) { }
}
