<div class="temporaryPasswordModalClass">
  <lib-loader [show]="isLoading"></lib-loader>
  <div class="modal-body">
    <div class="temporary-password-title">
      {{'PASSWORD.SET_TEMPORARY_PASSWORD' | translate}}
    </div>
    <form [formGroup]="form"
          class="temporary-password-form">
      <lib-password-input class="form-field-input"
                          formControlName="newPassword"
                          inputId="newPassword"
                          [hasError]="isSubmitted && form.get('newPassword').invalid"
                          [hasSuccess]="isSubmitted && form.get('newPassword').valid"
                          (OnChange)="checkPassword()">
      </lib-password-input>
    </form>
  </div>
  <div class="modal-footer">
    <lib-button class="modal-footer-btn"
                (OnClick)="onCancel()"
                [title]="'GENERAL.CANCEL' | translate">
    </lib-button>
    <lib-button class="modal-footer-btn filled"
                (OnClick)="confirmChangePassword()"
                [title]="'GENERAL.APPLY' | translate">
    </lib-button>
  </div>
</div>
