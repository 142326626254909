import { AuthService } from "../services/auth/auth.service";
import { TokenInterceptorConfig } from "ngx-strong-frontend-lib/services/token-interceptor";
import { Router } from "@angular/router";

export function tokenInterceptorConfigFactory(
    authService: AuthService,
    router: Router
): TokenInterceptorConfig {
    return {
        getToken: () => authService.getToken(),
        tokenable: req => authService.isAuthorized() && !req.headers.get('Authorization') && !req.url.includes('/oauth/token'),
        fallback: () => {
            router.navigate(['/auth']);
        },
        refreshToken: () => authService.refreshToken()
    };
}
