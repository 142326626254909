import { AppRoutingModule } from './app.routing';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { ComponentLibraryModule } from 'ngx-strong-frontend-lib/module';
import {
  ACCESS_OBJECTS_KEY,
  ACCESS_RIGHTS_KEY,
  AUTH_SYSTEM_PARAMETER_NAME,
  MENU_ICONS,
  MENU_OPENED_KEY,
  MENU_OPENED_URLS,
  MENU_URLS,
  USER_MENU_KEY
} from "@app/app.enums";
import { LazyModuleLoaderModule } from 'ngx-strong-frontend-lib/components/lazy-module-loader'
import { AuthUniversalGridApiService } from "@core/services/api/auth-universal-grid-api.service";
import { AuthUniversalGridUtilsService } from "@shared/services/auth-universal-grid-utils.service";
import { UniversalGridApiService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-api';
import { UniversalGridUtilsService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-utils';
import { NoAccessConfig, NoAccessModule } from 'ngx-strong-frontend-lib/components/no-access';
import { SystemParamsService } from './core/services/system-params.service';
import { map } from 'rxjs/operators';
import { PageWrongConfig, PageWrongModule } from 'ngx-strong-frontend-lib/components/page-wrong';
import { PageNotFoundConfig, PageNotFoundModule } from 'ngx-strong-frontend-lib/components/page-not-found';
import { LogoComponent } from './shared/components/logo/logo.component';
import { of } from 'rxjs';
import { DynamicImportTranslationLoader } from '@core/services/dynamic-import-translation-loader.service';

function noAccessFactory(systemParamsService: SystemParamsService): NoAccessConfig {
  return {
    supportPhone$: systemParamsService.getPublicSystemParams()
      .pipe(map(res => res?.[AUTH_SYSTEM_PARAMETER_NAME.DEVELOPER_PHONE] ?? '')),
    supportEmail$: systemParamsService.getPublicSystemParams()
      .pipe(map(res => res?.[AUTH_SYSTEM_PARAMETER_NAME.DEVELOPER_EMAIL] ?? ''))
  }
}
function pageWrongFactory(): PageWrongConfig {
  return {
    homeUrl$: of('/home'),
    homeUrlTarget: 'inner',
    logoComponent: LogoComponent
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContextMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: DynamicImportTranslationLoader
      }
    }),
    AppRoutingModule,
    ComponentLibraryModule.forRoot({
      menuConfig: {
        menuOpenedStorageKey: MENU_OPENED_KEY,
        userMenuStorageKey: USER_MENU_KEY,
        menuUrls: MENU_URLS,
        menuIcons: MENU_ICONS,
        menuOpenedUrls: MENU_OPENED_URLS,
      },
      accessObjectsStorageKey: ACCESS_OBJECTS_KEY,
      accessRightsStorageKey: ACCESS_RIGHTS_KEY,
      userAccess: null
    }),
    LazyModuleLoaderModule,
    NoAccessModule.forRoot({
      provide: NoAccessConfig,
      useFactory: noAccessFactory,
      deps: [SystemParamsService]
    }),
    PageWrongModule.forRoot({
      provide: PageWrongConfig,
      useFactory: pageWrongFactory
    }),
    PageNotFoundModule.forRoot({
      provide: PageNotFoundConfig,
      useValue: <PageNotFoundConfig>{
        mainUrl: '/home',
        logoComponent: LogoComponent
      }
    })
  ],
  providers: [
    Title,
    { provide: UniversalGridApiService, useClass: AuthUniversalGridApiService },
    { provide: UniversalGridUtilsService, useClass: AuthUniversalGridUtilsService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

